@import "src/lib/sea/color.scss";

.title {
  font-size: 1.3rem;
  position: relative;
  margin-bottom: .8em;
  text-transform: capitalize;
}

.title.underline {
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: -4px;
    right: 0;
    background-color: $dark-300;
  }
}

.title.white {
  color: $light-700;
}

.title.white.underline {
  &::after {
    background-color: $light-300;
  }
}

.title.black {
  color: $dark-300;
}

.title.fill {
  background-color: $dark-300 !important;
  color: $light-700;
  font-weight: 500;
  padding: 0.3em 0.3em 0.1em 0.3em;
  width: fit-content;
}

.title.modern {
  color: #333;
  border-left: 4px solid #336699;
  padding-left: 12px;
  &::after {
    display: none;
  }
}

.ar .title.modern {
  border-left: none;
  border-right: 4px solid #336699;
  padding-left: 0;
  padding-right: 12px;
}
